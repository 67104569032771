import React from 'react';
import HomePagePlaceholderUI from './HomePagePlaceholderUI';
import TopBar from './TopBar';
import { useRouter } from 'next/router';

const HomePagePostSignIn = () => {
  const { pathname } = useRouter();
  
  let title: string;

  switch (pathname) {
    case '/':
      title = 'Shop';
      break;

    default:
      title = 'Shop';
      break;
  }

  return (
    <>
      <TopBar title={title} />
      <HomePagePlaceholderUI />
      {/* <Navbar /> */}
    </>
  );
};

export default HomePagePostSignIn;
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import React, { FC, ReactNode } from 'react';
import { colorTokens } from '../theme/color-tokens';

interface BannerProps extends BoxProps {
  bgColor?: string;
  color?: string;
  children?: ReactNode;
}

const Banner: FC<BannerProps> = ({
  children,
  bgColor = colorTokens.backgroundPrimary,
  color = colorTokens.textOnImage,
  ...props
}) => {
  return (
    <StyledBanner bgColor={bgColor} color={color} {...props}>
      {children}
    </StyledBanner>
  );
};

export default Banner;

const StyledBanner = styled(Box)<BannerProps>(({bgColor, color}) => ({
    padding: "4px 8px",
    backgroundColor: bgColor,
    color: color,

    // Using this inside of just rendering a paragraph because this banner may also render content other than text 
    //but we also want to be text to be of this styling
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
}));
import { Box, Stack } from '@mui/material';
import React, { lazy } from 'react';
import HomePagePlaceholderUI from './HomePagePlaceholderUI';
import { useAuth } from '../../lib';
const HomeNavbar = lazy(() => import('@/components/HomePage/HomeNavbar'));

const HomePagePreSignIn = () => {
  const { isLoggedIn, login, signup } = useAuth();
  return (
    <>
      <HomeNavbar isLoggedIn={false} login={login} signup={signup} />
      <HomePagePlaceholderUI />
    </>
  );
};

export default HomePagePreSignIn;
import React, { lazy, useEffect, useMemo } from 'react';
import { NextPage } from 'next';
import { withSession, withTranslations } from '@/ssr';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { useAuth } from '../lib';
// import ProgressSpinner from '../components/ProgressSpinner';
// const HomeNavbar = lazy(() => import('../components/HomePage/HomeNavbar'));
// const HomeHeader = lazy(() => import('../components/HomePage/HomeHeader'));
// const ComingSoon = lazy(() => import('../components/HomePage/ComingSoon'));
// const StepsToSell = lazy(() => import('../components/HomePage/StepsToSell'));
// const OurStory = lazy(() => import('../components/HomePage/OurStory'));
import RoutingLoaderLayout from '../layouts/RoutingLoaderLayout';
import HomePagePreSignIn from '../componentsV2/homepage/HomePagePreSignIn';
import HomePagePostSignIn from '../componentsV2/homepage/HomePagePostSignIn';
const HomeFooter = lazy(() => import('../components/HomePage/HomeFooter'));

// const HomeNavbar = lazy(() => import('../components/HomePage/HomeNavbar'));
// const HomeHeader = lazy(() => import('../components/HomePage/HomeHeader'));
// const ComingSoon = lazy(() => import('../components/HomePage/ComingSoon'));
// const StepsToSell = lazy(() => import('../components/HomePage/StepsToSell'));
// const OurStory = lazy(() => import('../components/HomePage/OurStory'));

const Index: NextPage = () => {
  const theme = useMemo(() => createTheme(), []);
  const { signup, login, isLoggedIn } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <RoutingLoaderLayout>
        <Box>
          {!isLoggedIn ? (
            <HomePagePreSignIn />
          ) : (
            // <Box>
            //   <Suspense fallback={<ProgressSpinner size="3rem" />}></Suspense>
            //   <ComingSoon />
            //   <HomeHeader />
            //   <StepsToSell />
            //   {/* <OurStory /> */}
            // </Box>
            <HomePagePostSignIn />
          )}
          <HomeFooter />
        </Box>
      </RoutingLoaderLayout>
    </ThemeProvider>
  );
};
export default Index;

export const getServerSideProps = withSession(
  withTranslations(async (context) => {
    const { req, res } = context;

    // Check if the URL contains the "fbclid" parameter
    const isFbRedirect = req.url?.includes('/?fbclid') || false;

    if (isFbRedirect) {
      // Perform a server-side redirect
      return {
        redirect: {
          destination: '/', // Replace with your target URL
          permanent: false // Use false for temporary redirect (302), true for permanent (301)
        }
      };
    }

    return {
      props: {}
    };
  })
);

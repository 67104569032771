import { Box, Grid, Stack, styled } from '@mui/material';
import React from 'react';
import Banner from '../Banner';
import { Overline, Paragraph } from '../../theme/text-variants';
import { colors } from '../../theme/colors';
import { colorTokens } from '../../theme/color-tokens';
import ImageIcon from '@/img/image-opacity-25.svg';

// Mock Data
const hosts = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
const lives = ['1', '2', '3', '4', '5'];
const products = ['1', '2', '3', '4'];

const HomePagePlaceholderUI = () => {
  return (
    <Stack overflow="hidden">
      <Banner textAlign="center">Select a host to sell your products remotely</Banner>
      <Stack alignItems="center">
        <Stack maxWidth="430px" gap="4px">
          <Stack>
            <HeadingContainer>
              <Overline size="md" color={colorTokens.textDefault}>
                HOSTS
              </Overline>
            </HeadingContainer>
            <Stack position="relative" pl="16px" maxWidth="100vw" overflow={'hidden'} direction="row" gap="12px">
              <Mask>
                <Paragraph pr="16px" size="lg" fontWeight="bold">
                  Coming Soon
                </Paragraph>
              </Mask>
              {hosts.map((host) => (
                <HostCardContainer key={host}>
                  <HostCardPlaceholder>
                    <ImageIcon width="24px" height="24px" />
                  </HostCardPlaceholder>
                </HostCardContainer>
              ))}
            </Stack>
          </Stack>
          <Stack>
            <HeadingContainer>
              <Overline size="md" color={colorTokens.textDefault}>
                LIVE NOW
              </Overline>
            </HeadingContainer>
            <Stack position="relative" pl="16px" maxWidth="100vw" overflow={'hidden'} direction="row" gap="12px">
              <Mask>
                <Paragraph pr="16px" size="lg" fontWeight="bold">
                  Coming Soon
                </Paragraph>
              </Mask>
              {lives.map((live) => (
                <LiveCardPlaceholder key={live}>
                  <ImageIcon width={101} height={101} />
                </LiveCardPlaceholder>
              ))}
            </Stack>
          </Stack>
          <Stack>
            <HeadingContainer>
              <Overline size="md" color={colorTokens.textDefault}>
                PRODUCTS
              </Overline>
            </HeadingContainer>
            <Grid position="relative" container p="0px 16px" spacing="16px">
              <Mask>
                <Paragraph size="lg" fontWeight="bold">
                  Coming Soon
                </Paragraph>
              </Mask>
              {products.map((product) => (
                <Grid item xs={6}>
                  <ProductsPlaceholderCard key={product}>
                    <ImageIcon width={101} height={101} />
                  </ProductsPlaceholderCard>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomePagePlaceholderUI;

// Did not make these reusable as these will be replaced once we have actual live show and seller data

export const Mask = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, ${colors.white} 100%)`
}));

const HeadingContainer = styled(Box)(() => ({
  padding: '8px 16px'
}));

const HostCardPlaceholder = styled(Box)(() => ({
  display: 'flex',
  width: '72px',
  height: '72px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flexShrink: 0,

  borderRadius: '16px',
  background: colors.gray100
}));

const HostCardContainer = styled(Box)(() => ({
  width: '72px',
  height: '72px',
  padding: '2px',
  borderRadius: '18px',
  border: `2px solid ${colorTokens.borderDark}`
}));

const LiveCardPlaceholder = styled(Box)(() => ({
  display: 'flex',
  minWidth: '162px',
  minHeight: '230px',
  justifyContent: 'center',
  alignItems: 'center',

  borderRadius: '8px',
  background: colors.gray100
}));

const ProductsPlaceholderCard = styled(Box)(() => ({
  display: 'flex',
  height: '173px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  border: `1px solid ${colorTokens.borderSubtle}`,
  background: colors.gray100
}));